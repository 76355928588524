/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #000;
$default-primary-base-color: #FC4C02;
$default-primary-dark-color: #373A36;
$default-primary-light-color: #3D3935;
$default-secondary-base-color: #D14124;
$default-secondary-dark-color: #333F48;
$default-secondary-light-color: #47494D;
$font-standard-size: 62.5%;

// Colors
$grey1: #63666A;
$grey2: #979899;
$grey3: #CACCCF;
$grey4: #E3E4E6;
$grey5: #F9F9F9;
$grey6: #FFFEFC;
$grey7: #B9B9B9;
$grey8: #B3B3B3;
$grey9: #333333;
$grey10: #e5e6e7;
$grey11: #E3E3E3;
$grey12: #c1c2c4;
$grey13: #C9C9C9;
$grey14: #EBEBEB;
$grey15: #F8F6F0;
$grey16: #DADADA;
$grey17: #F5F5F5;
$grey18: #A9A9A9;
$grey19: #D6DED4;

$red1: #EA122C;
$red2: #AE2424;

$orange1: #DC582A;
$orange2: #F0622E;
$orange3: #F2500C;
$orange4: #F28030;

$yellow1: #FFC20D;
$yellow2: #FFCC00;
$yellow3: #F5890F;
$yellow4: #ffcf43;

$green1: #D6DEB4;

// Layout
$layout__max-width: 1300px;

// Box Shadow
$box-shadow1: 0px 3px 35px rgba(0, 0, 0, 0.1);
$box-shadow2: 0px 3px 15px rgba(0, 0, 0, 0.1);
$box-shadow3: 0px 5px 15px rgba(0, 0, 0, 0.8);
$box-shadow4: 0px 1px 5px rgba(0, 0, 0, 0.1);
$box-shadow5: 0px 5px 15px rgba(0, 0, 0, 0.5);
$box-shadow6: 0px 4px 15px rgba(0, 0, 0, 0.16);
$box-shadow7: 0px 1px 10px rgba(0, 0, 0, 0.26);
$box-shadow8: 0px -10px 20px rgba(0, 0, 0, 0.1);
$box-shadow9: 0px 3px 6px rgba(0, 0, 0, 0.26);
$box-shadow10: inset 50em 50em rgba(0, 0, 0, .2);
$box-shadow11: 0px -10px 20px rgba(0, 0, 0, 0.05);
$box-shadow12: 3px 3px 5px rgba(151, 152, 153, 0.4);

// Animation
$transition1: all .5s;
$transition2: all .3s;

// Icons
$icomoon-font-family: "icomoon-font-family" !default;

// Typography
$font-josefin-sans: 'Josefin Sans';
$font-source-sans-pro: 'Source Sans Pro';
$font-inter-sans: 'Inter';
$base-font-family: $font-inter-sans;

$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

$letter-spacing: 0.3px;
$letter-spacing1: 0.35px;
$letter-spacing2: 0.42px;
$letter-spacing3: 0.6px;
$letter-spacing4: 0.7px;
$letter-spacing5: 0.8px;
$letter-spacing6: 0.85px;
$letter-spacing7: 1.2px;
$letter-spacing8: 0.53px;
$letter-spacing9: 0.38px;
$letter-spacing10: 0.24px;
$letter-spacing11: 0.5px;

$h1-font-color: var(--primary-light-color);
$h1-font-weight: $font-weight-semibold;
$h1-letter-spacing: $letter-spacing7;
$h1-letter-spacing-mobile: $letter-spacing3;
$h1-font-size-desktop: 48px;
$h1-font-size-mobile: 26px;
$h1-font-line-height-desktop: 59px;
$h1-font-line-height-mobile: 31px;

$h2-font-color: var(--primary-light-color);
$h2-font-weight: $font-weight-semibold;
$h2-letter-spacing: $letter-spacing5;
$h2-letter-spacing-mobile: $letter-spacing3;
$h2-font-size-desktop: 32px;
$h2-font-size-mobile: 24px;
$h2-font-line-height-desktop: 39px;
$h2-font-line-height-mobile: 29px;

$h3-font-color: var(--primary-light-color);
$h3-font-weight: $font-weight-semibold;
$h3-letter-spacing: 0;
$h3-font-size-desktop: 24px;
$h3-font-size-mobile: 20px;
$h3-font-line-height-desktop: 30px;
$h3-font-line-height-mobile: 26px;

$h4-font-color: var(--secondary-light-color);
$h4-font-weight: $font-weight-medium;
$h4-letter-spacing: $letter-spacing4;
$h4-letter-spacing-mobile: $letter-spacing3;
$h4-font-size-desktop: 20px;
$h4-font-size-mobile: 18px;
$h4-font-line-height-desktop: 28px;
$h4-font-line-height-mobile: 22px;

$h5-font-color: var(--primary-dark-color);
$h5-font-weight: $font-weight-semibold;
$h5-letter-spacing: $letter-spacing6;
$h5-font-size-desktop: 17px;
$h5-font-size-mobile: $h5-font-size-desktop;
$h5-font-line-height-desktop: 21px;
$h5-font-line-height-mobile: $h5-font-line-height-desktop;

$h6-font-color: var(--primary-light-color);
$h6-font-weight: $font-weight-medium;
$h6-letter-spacing: $letter-spacing1;
$h6-font-size-desktop: 15px;
$h6-font-size-mobile: $h6-font-size-desktop;
$h6-font-line-height-desktop: 19px;
$h6-font-line-height-mobile: $h6-font-line-height-desktop;

$subtitle-primary-font-color: var(--color-gray2);
$subtitle-primary-font-size: 15px;
$subtitle-primary-font-line-height: 19px;
$subtitle-primary-font-weight: $font-weight-medium;
$subtitle-primary-letter-spacing: $letter-spacing1;

$subtitle-secondary-font-color: var(--primary-light-color);
$subtitle-secondary-font-size: 15px;
$subtitle-secondary-font-line-height: 19px;
$subtitle-secondary-font-weight: $font-weight-regular;
$subtitle-secondary-letter-spacing: $letter-spacing1;

$paragraph-font-family: $base-font-family;
$paragraph-font-color: var(--body-paragraph-color);
$paragraph-font-weight: $font-weight-regular;
$paragraph-letter-spacing: $letter-spacing2;
$paragraph-font-size-desktop: 15px;
$paragraph-font-size-mobile: $paragraph-font-size-desktop;
$paragraph-font-line-height-desktop: 22px;
$paragraph-font-line-height-mobile: $paragraph-font-line-height-desktop;
