/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountInformation {
    &-Heading {
        @include mobile {
            display: none;
        }
    }

    &-Wrapper {
        background: var(--my-account-content-background);
        padding: 20px 10px;
    
        @include desktop {
            padding: 20px;
            border-radius: 5px;
        }

        .Form {
            .FieldForm {
                &-Fields {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    margin-bottom: 0;
                }

                &-Section {
                    width: 100%;
                    margin-bottom: 10px;

                    @include desktop {
                        max-width: 320px;
                    }

                    &WithSpace {
                        margin-bottom: 0;

                        @include desktop {
                            max-width: inherit;
                        }

                        .FieldForm-Legend {
                            margin-top: 30px;
                        }

                        .Field-Wrapper {
                            @include desktop {
                                max-width: 320px;
                            }
                        }
                    }

                    &:not(.FieldForm-SectionWithSpace) {
                        .Field-Wrapper {
                            &:nth-child(2),
                            &:nth-child(3) {
                                width: 48.5%;
                                float: left;
                            }
    
                            &:nth-child(2) {
                                margin-bottom: 25px;
                                margin-right: 3%;
                            }
    
                            &:nth-child(3) {
                                margin-top: 20px;
                                margin-bottom: 25px;
                            }
    
                            &:nth-child(4) {
                                clear: both;
                            }
                        }
                    }
                }

                &-Body {
                    .Button {
                        margin-top: 30px;
                    }
                }
            }

            .Field {
                &-Wrapper {
                    &_type_checkbox {
                        &:last-of-type {
                            margin-top: 15px;
                        }
                    }
                }

                &_type {
                    &_checkbox {
                        width: max-content;
                    }
                }
            }
        }
    }

    &-Submit {
        min-width: 110px;

        @include desktop {
            margin-block-start: 30px;
        }
    }
}
