/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-cancel-orders
 * @author ashangunawardena
 */

:root {
    --cancel-order-popup-width: var(--popup-min-width);
}

.MyAccountCancelOrderPopup {
    &.Popup {
        .Popup {
            &-Heading {
                line-height: 24px;
                margin-bottom: 8px;
                
                @include desktop {
                    padding: 0 20px;
                }
            }
    
            &-CloseBtn {
                top: 0;
            }
    
            &-Content {
                @include desktop {
                    overflow: inherit;
                    max-width: var(--cancel-order-popup-width);
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }

    &-Container {
        @include desktop {
            @include custom-scroll;

            overflow: hidden;
            overflow-y: auto;
            max-height: 63vh;
            padding: 0 20px;
        }

        @include desktop-xxl {
            max-height: 57vh;
        }
    }

    &-Subtitle {
        padding-right: 30px;

        p {
            font-size: 14px;
            color: var(--primary-light-color);
            letter-spacing: $letter-spacing2;
        }
    }

    .Form {
        .Field-Wrapper {
            &_type_textarea {
                .Field {
                    &-LabelContainer {
                        .Field-SubTitle {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    select {
        @include desktop {
            max-width: 360px;
        }
    }

    textarea {
        min-height: 150px;
        margin-bottom: 30px;
    }

    &-ItemFields {
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid var(--primary-divider-color);

        input {
            width: 100%;
        }
    }

    &-RemainingQty {
        font-size: 12px;
        margin-top: 5px;
        color: var(--primary-light-color);
        letter-spacing: $letter-spacing1;
    }

    &-ReturnItemsFields {
        .Button {
            width: auto;
        }
    }

    &-DeleteButton {
        margin-top: 20px;
    }

    &-ButtonWrap {
        display: grid;
        grid-gap: 10px;

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
