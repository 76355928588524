/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --wishlist-item-border-color: var(--expandable-content-divider-color);
}

.MyAccountMyWishlist {
    --myaccount-wishlist-action-bar-offset: 70px;

    &_isWishlistEmpty {
        .MyAccountMyWishlist-ActionBar {
            @include mobile {
                position: static;
                padding: 15px 0 0;
            }
        }
    }

    &-Products {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: max-content;
        grid-gap: 10px;
        top: 0;
        padding-bottom: 0;

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
            inset-block-start: var(--myaccount-wishlist-action-bar-offset);
            padding-block-end: var(--myaccount-wishlist-action-bar-offset);
        }

        @include narrow-desktop {
            grid-template-columns: repeat(3, 1fr);
            inset-block-start: var(--myaccount-wishlist-action-bar-offset);
            padding-block-end: var(--myaccount-wishlist-action-bar-offset);
        }

        @include wide-desktop {
            grid-template-columns: repeat(4, 1fr);
            inset-block-start: 0;
        }

        @include mobile {
            grid-gap: 20px 10px;
            grid-template-columns: repeat(2, 1fr);
            border: 0;
            max-width: 100%;
            margin: 0 auto;
        }

        @include desktop {
            grid-gap: 20px;
        }

        .Draggable {
            @include mobile {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }

            .SwipeToDelete {
                @include mobile {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                }

                .ProductCard {
                    @include mobile {
                        width: 100%;
                    }

                    .WishlistItem {
                        &-SelectWrapper,
                        &-ContentWrapper {
                            @include mobile {
                                width: 100%;
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }

    &-NoProducts {
        p {
            margin-bottom: 0;
        }
    }

    &-ActionBar {
        display: flex;
        padding: 0;
        padding-block-end: 12px;
        justify-content: flex-end;

        @include desktop {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        @include mobile {
            background-color: var(--my-account-content-background);
            inset-block-end: var(--footer-total-height);
            display: block;
            inset-inline-start: 0;
            padding: 10px;
            position: fixed;
            width: 100%;
            z-index: 10;
            left: 0;

            .hideOnScroll & {
                transform: translateY(var(--footer-nav-height));
            }
        }

        @include tablet {
            .AddToCart-Icon {
                display: none;
            }
        }
    }

    &-ActionBarActionWrapper {
        display: flex;
        justify-content: space-between;
        margin-block-end: 10px;
    }

    &-NoProductsButton {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &-Heading {
        @include mobile {
            display: none;
        }
    }

    &-Container {
        background: var(--my-account-content-background);
        padding: 20px 10px;

        @include mobile {
            margin-bottom: 80px;
        }

        @include desktop {
            padding: 20px;
            border-radius: 5px;
        }
    }

    &-Button {
        @include mobile {
            width: 100%;
        }

        .CartIcon {
            display: none;
        }
    }

    &-ClearRemoveItemsButton {
        text-transform: uppercase;
        color: initial;

        &.Button {
            width: auto;
        }
    }

    &-ClearWishlistButton {
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        @include desktop {
            order: 1;
        }

        &.Button {
            width: auto;
        }
    }

    &-ShareWishlistButton {
        @include share-icon;

        &::before {
            color: var(--primary-base-color);
        }

        &:hover {
            &::before {
                @include desktop {
                    color: var(--color-white);
                }
            }
        }

        .ShareIcon {
            display: none;
            margin-right: 5px;
        }

        &:disabled {
            pointer-events: none;

            &::before {
                color: var(--color-gray7);
            }
        }

        @include desktop {
            margin-left: 20px;
        }
    }
}

.MyAccount-TabContent_activeTab_my-wishlist {
    .MyAccount-Heading {
        .MyAccount-SubHeading {
            display: none;
        }
    }
}
