/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-track-shipping
 * @author ashangunawardena
 */

.MyAccountTrackShipping {
    .Popup {
        &-Header {
            @include desktop {
                padding: 0 20px;
            }
        }

        &-CloseBtn {
            @include desktop {
                top: 0;
                right: 20px;
            }
        }

        &-Content {
            @include desktop {
                padding-left: 0;
                padding-right: 0;
                overflow: inherit;
            }
        }
    }
    
    &-Container {
        margin-top: 10px;

        @include desktop {
            @include custom-scroll;

            margin-top: 0;
            max-height: 411px;
            min-height: 411px;
            overflow: hidden;
            overflow-y: auto;
            padding: 0 20px;
        }
    }

    &-Subtitle {
        margin: 20px 0 30px 0;
    }

    &-RightContent {
        margin-left: 20px;
    }

    &-Status {
        text-transform: uppercase;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: $letter-spacing9;
        font-weight: $font-weight-medium;
        margin-bottom: 10px;
    }

    &-Date {
        font-size: 12px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing9;
    }

    &-ShipmentMessage {
        display: flex;
    }

    &-ShipmentMessageContent {
        @include paragraph-text;
        font-weight: $font-weight-medium;
        color: var(--primary-light-color);
        margin-left: 20px;
        word-break: break-word;
    }

    &-ShipmentMessageContent {
        flex: 1;
    }

    &-Pointer {
        background: var(--color-orange4);
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }

    &-StepContainer {
        display: flex;
    }

    &-RightContent {
        flex: 1;
    }

    &-SideLine {
        height: 63px;
        width: 2px;
        background: var(--color-grey11);
        margin-left: 7px;
    }
}
