/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountOrderTab {
    &-Item {
        margin: 0;
        padding-inline-start: 0;
        text-align: center;

        &:last-child {
            margin: 0;
            padding: 0;
        }

        &::before {
            content: '';
        }

        button {
            @include desktop {
                border: 1px solid var(--color-white);
                border-bottom: 0;
            }
        }

        &_isActive {
            background-color: var(--color-gray4);

            @include desktop {
                background: none;
            }

            button {
                @include desktop {
                    border-color: var(--color-gray3);
                    color: var(--primary-light-color);
                }
            }
        }
    }

    &-Button {
        @include h6;
        
        margin: 0;
        padding: 17px 10px;
        cursor: pointer;
        text-transform: uppercase;
        text-align: center;

        @include desktop {
            padding: 15px 20px;
            border-bottom: 0;
            text-transform: capitalize;
            letter-spacing: $letter-spacing9;
            background: $white;
        }

        &:hover {
            @include desktop {
                text-decoration: underline;
            }
        }
    }
}
