/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-return-orders
 * @author ashangunawardena
 */

.MyAccountMyReturn {
    background: var(--my-account-content-background);
    padding: 20px 10px;
    width: 100%;

    @include desktop {
        padding: 20px;
        border-radius: 5px;
    }

    .MyAccountMyReturn {
        &-TrackingShipments {
            > form {
                width: 100%;
    
                @include desktop {
                    width: 100%;
                }
            }
        }
    }

    &-Status {
        font-size: 14px;
        line-height: 18px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing1;
        margin-bottom: 25px;

        span {
            text-transform: lowercase;
            display: inline-block;

            &:first-letter {
                text-transform: uppercase
            }
        }
    }

    &-ReturnMobileItemsTable {
        border: 0;
        border-bottom: 1px solid var(--secondary-divider-color);
        width: 100%;

        &:last-of-type {
            margin-bottom: 0;
            border-bottom: 0;
        }

        tr {
            &:last-of-type {
                td {
                    padding-bottom: 20px;
                }
            }
        }

        td {
            padding-top: 0;
            padding-bottom: 15px;

            &.MyAccountMyReturn-ReturnMobileItemsLabel {
                @include h6;

                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &-RequestInfoDetailBlock {
        .KeyValueTable {
            margin-bottom: 0;
        }
    }

    &-RequestInfo {
        display: grid;
        margin-bottom: 40px;
        grid-gap: 30px;

        @include desktop {
            grid-template-columns: 1fr 1fr;
            grid-gap: 0;
        }

        h5 {
            border-bottom: 1px solid var(--secondary-divider-color);
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }

    &-TrackingShipmentsTable {
        width: 100%;

        @include mobile {
            border: 0;
        }

        thead {
            th {
                @include mobile {
                    display: none;
                }
            }
        }

        tbody {
            tr {
                &:hover {
                    background: transparent;
                }
            }
        }
    }

    &-TrackingShipments {
        margin-bottom: 40px;

        h5 {
            margin-bottom: 20px;

            @include mobile {
                border-bottom: 1px solid var(--secondary-divider-color);
                padding-bottom: 20px;
            }
        }
    }

    &-TrackingShipmentsTableRow {
        @include mobile {
            display: flex;
            flex-wrap: wrap;
        }

        td {
            @include h6;

            @include mobile {
                width: 100%;
                padding: 0 0 15px;
            }

            &:last-of-type {
                @include mobile {
                    padding-bottom: 0;
                }
            }

            &::before {
                @include mobile {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-bottom: 10px;
                }
            }
            
            &:nth-child(1) {
                &::before {
                    @include mobile {
                        content: 'Carrier';
                    }
                }
            }

            &:nth-child(2) {
                &::before {
                    @include mobile {
                        content: 'Number';
                    }
                }
            }

            &:nth-child(3) {
                &::before {
                    @include mobile {
                        content: 'Action';
                    }
                }
            }
        }

        .Field-Wrapper {
            &:first-of-type {
                margin-top: 0;
            }

            .Field {
                input {
                    width: 100%;
                }
            }

            .FieldSelect {
                &_isExpanded {
                    box-shadow: $box-shadow1;
                }
            }
        }

        .Button {
            --button-padding: 0 20px;
        }
        
        .Field-ErrorMessages {
            font-weight: $font-weight-regular;
        }
    }

    &-RequestInfoData {
        @include paragraph-text;
    }

    &-ShippingAddress {
        .KeyValueTable {
            &-Content {
                display: inline-block;
                width: 100%;
                border: 0;
                padding: 0;

                .KeyValueTable-Row {
                    &:hover {
                        margin: 0;
                        padding: 0;
                        background: transparent;
                        width: auto;
                    }
                }
            }

            &-Row {
                border: 0;
                display: inline-block;
                width: auto;
            }

            &-Column {
                min-width: auto;
                padding: 0;
                padding-right: 5px;

                @include print-only {
                    border-bottom: 0;
                }

                &:nth-child(1) {
                    display: none;
                }
            }
        }  
    }

    &-ReturnItems {
        margin-bottom: 40px;

        table {
            width: 100%;

            @include mobile {
                border: 0;
            }
        }

        h5 {
            margin-bottom: 20px;

            @include mobile {
                border-bottom: 1px solid var(--secondary-divider-color);
                padding-bottom: 20px;
            }
        }
    }

    &-ReturnOrderComments {
        font-size: 15px;

        @include desktop {
            width: 50%;
        }

        h5 {
            border-bottom: 1px solid var(--secondary-divider-color);
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }

    &-ReturnOrderCommentTitle {
        display: flex;
    }

    .Form {
        @include desktop {
            width: 50%;
        }

        textarea {
            min-height: 120px;
            margin-bottom: 30px;
        }
    }

    &-ReturnOrderCommentAuthor {
        padding-right: 10px;
        margin-bottom: 5px;
    }

    &-ReturnOrderCommentDate {
        font-size: 12px;
        color: var(--secondary-light-color);
        line-height: 18px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing1;
    }

    &-ReturnItemsTable {
        thead {
            th {
                &:nth-child(1) {
                    @include desktop {
                        width: 22%;
                    }
                }

                &:nth-child(2) {
                    @include desktop {
                        width: 16%;
                    }
                }
            }
        }
    }

    &-ItemsTableRow {
        td {
            @include h6;
            
            word-break: break-word;

            &:last-child {
                text-transform: lowercase;
                display: inline-block;
                padding-right: 20px;

                &:first-letter {
                    text-transform: uppercase
                }
            }
        }
    }
}
