/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --my-account-tab-list-item-color: var(--secondary-dark-color);
}

.MyAccountTabListItem {
    --border-width: 1px;
    --text-shadow: none;

    padding-inline-start: 0;
    margin-bottom: 0;
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-gray3);
    display: flex;
    width: 100%;
    align-items: center;

    @include mobile {
        padding-left: 0;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    @include desktop {
        padding: 10px 0;
    }

    @include myaccount-next-nav;

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;
        border-bottom: 0;

        button {
            &::after {
                content: none;
            }
        }
    }

    &:hover {
        .MyAccountTabListItem-Button {
            @include desktop {
                color: var(--primary-light-color);
                text-decoration: underline;
            }
        }
    }

    &:nth-child(1) {
        order: 1;
    }

    &:nth-child(2) {
        order: 2;
    }

    &:nth-child(3) {
        order: 6;
    }

    &:nth-child(9) {
        order: 4;
    }

    &:nth-child(10) {
        order: 5;
    }

    &:nth-child(4) {
        order: 6;
    }

    &:nth-child(5) {
        order: 7;
    }

    &:nth-child(6) {
        order: 8;
    }

    &:nth-child(7) {
        order: 9;
    }

    &:nth-child(8) {
        order: 3;
    }

    &:nth-child(11) {
        order: 11;
    }

    &:nth-child(12) {
        order: 12;
    }

    &_isActive {
        @include mobile {
            display: none;
        }

        button {
            font-weight: $font-weight-medium;
            color: var(--primary-light-color);

            &::after {
                color: var(--primary-light-color);
            }
        }
    }

    &-Button {
        min-width: 100%;
        min-height: 38px;
        text-align: start;
        text-shadow: var(--text-shadow);
        font-size: 14px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing1;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--color-gray2);

        @include desktop {
            min-width: 230px;
            min-height: 35px;
        }

        .ChevronIcon {
            display: none;
        }
    }
}
