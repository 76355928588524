/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-return-orders
 * @author ashangunawardena
 */

.MyAccountCreateReturn {
    background: var(--my-account-content-background);
    padding: 20px 10px;

    @include desktop {
        padding: 20px;
        border-radius: 5px;
    }

    h5 {
        margin-bottom: 20px;
    }

    .Field-Wrapper_type_textarea {
        margin-top: 30px;

        @include desktop {
            width: 50%;
            max-width: 420px;
        }

        textarea {
            min-height: 120px;
            margin-bottom: 30px;
        }
    }

    &-ReturnEmail {
        input {
            @include desktop {
                width: 50%;
                max-width: 420px;
            }
        }
    }

    &-OrderDetailMobile {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--secondary-divider-color);
    }

    &-OrderDetailMobileField {
        &:not(:last-of-type) {
            margin-bottom: 20px
        }
    }

    &-OrderDetailMobileLabel {
        @include h6;

        margin-bottom: 10px;
    }

    &-OrderDetailMobileValue {
        @include paragraph-text;
    }

    &-ItemFields {
        &:not(:last-of-type) {
            padding-bottom: 25px;
            margin-bottom: 25px;
            border-bottom: 1px solid var(--secondary-divider-color);
        }
    }

    &-OrderDetailTable {
        width: 100%;

        thead {
            th {
                &:nth-child(1) {
                    @include desktop {
                        width: 16%;
                    }
                }
            }
        }

        tbody {
            td {
                &:nth-child(3) {
                    @include desktop {
                        word-break: break-word;
                    }
                }
            }
        }
    }

    &-ReturnItemsFields {
        margin-top: 40px;

        @include desktop {
            width: 50%;
            max-width: 420px;
        }

        .FieldSelect-Clickable {
            &:after {
                right: 12%;

                @include desktop {
                    right: 15px;
                }
            }
        }

        h5 {
            border-bottom: 1px solid var(--color-gray3);
            padding-bottom: 20px;
        }

        input {
            width: 92%;

            @include desktop {
                width: 100%;
            }
        }

        select {
            min-width: 92%;
            width: 92%;

            @include desktop {
                min-width: 100%;
                width: 100%;
            }
        }

        .MyAccountCreateReturn-AddButton {
            margin-top: 30px;
            margin-bottom: 10px;
        }

        .Button_Secondary {
            margin-top: 30px;

            @include mobile {
                width: auto;
            }
        }
    }

    &-DeleteButton {
        @include close-icon;

        position: absolute;
        top: 35px;
        right: 0;

        @include desktop {
            right: -40px;
        }

        span {
            display: none;
        }
    }

    &-RemainingQty {
        font-size: 12px;
        line-height: 15px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing1;
        margin-top: 5px;
    }

    &-ShippingAddress {
        .KeyValueTable {
            border: 0;
            margin-bottom: 0;

            tr {
                display: none;
            }

            .KeyValueRow_label_Street {
                border: 0;

                th {
                    display: none;
                }

                display: block;
            }

            &-Content {
                border: 0;
                padding: 0;
            }

            &-Row {
                display: inline-block;
                width: auto;
                border-bottom: 0;

                &:hover {
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    width: auto; 
                }
            }

            &-Column {
                min-width: auto;
                padding: 0;
                padding-right: 5px;

                &:first-of-type {
                    display: none;
                }
            }
        }
    }

    .Form {
        .Field-Wrapper {
            &_type_textarea {
                .Field-LabelContainer {
                    .Field-SubTitle {
                        display: none;
                    }
                }
            }
        }
    }
}
