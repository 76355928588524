/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressBook {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr;
    padding-bottom: 20px;

    @include desktop {
        padding-bottom: 0;
    }

    .Button_Secondary {
        @include mobile {
            margin-bottom: -10px;
        }

        @include desktop {
            position: absolute;
            right: 0;
            top: -70px;
        }
    }

    &-Button {
        @include desktop {
            inset-inline-end: 0;
        }
    }

    button + button {
        margin-inline-start: 12px;

        @include mobile {
            margin-block-start: 14px;
            margin-inline-start: 0;
        }
    }
}
