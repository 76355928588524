/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressTable {
    font-size: 14px;

    td,
    th {
        @include desktop {
            width: 50%;
        }
    }

    .KeyValueTable {
        margin-bottom: 20px;
    }

    .Button {
        &_likeLink {
            background: none;
            text-transform: uppercase;
            font-weight: $font-weight-medium;
            letter-spacing: $letter-spacing4;

            &:not([disabled]) {
                color: var(--primary-base-color);
            }

            @include desktop {
                margin-left: 20px;
            }
        }
    }
}
