/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-gift-card
 * @author ashangunawardena
 */
 
:root {
    --my-account-gift-card-background: var(--color-white);
    --my-account-gift-card-fields-desktop-width: 338px;
}

.MyAccountGiftCard {
    background: var(--my-account-gift-card-background);
    padding: 20px 10px;

    @include desktop {
        padding: 20px;
        border-radius: 5px;
    }

    &-Heading {
        @include mobile {
            display: none;
        }
    }

    &-Fields {
        @include desktop {
            max-width: var(--my-account-gift-card-fields-desktop-width);
        }

        input {
            width: 100%;
        }

        .Field {
            &-Wrapper {
                &:first-of-type {
                    @include mobile {
                        margin-top: 0;
                    }
                }
            } 
        }
    }

    &-ButtonContainer {
        margin-top: 30px;

        @include mobile {
            padding: 0;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-GiftCardNumber {
        margin-bottom: 15px;

        strong {
            font-weight: $font-weight-semibold;
        }
    }

    &-CurrentBalance {
        margin-bottom: 30px;
    }

    &-CheckStatus {
        cursor: pointer;
        color: var(--link-color);
        font-size: 15px;
        font-weight: $font-weight-semibold;
        letter-spacing: $letter-spacing8;

        @include desktop {
            padding-left: 20px;
        }

        @include mobile {
            padding-top: 20px;
        }
    } 

    &-ValidationError {
        input {
            border: 1px solid var(--primary-error-color);
        }
    }
}
