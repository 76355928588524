/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrderTotals {
    &-GrandTotal {
        border-top: 1px solid var(--color-gray3);
        padding-top: 20px;

        dt,
        dd {
            @include h5;
        }
    }

    &-BottomTotalContainer {
        @include desktop {
            display: flex;
            justify-content: flex-end;
        }

        @include print-only {
            flex-wrap: wrap;
            width: 100%;
        }
    }

    &-BottomTotal {
        @include desktop {
            width: 100%;
            max-width: 398px;
        }

        dl {
            @include h6;
            
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;

            &:last-of-type {
                margin-bottom: 0;
            }

            dt {
                width: 55%;

                @include mobile {
                    text-align: left;
                }

                @include desktop {
                    text-align: right;
                }
            }
            
            dd {
                width: 45%;
                text-align: right;
            }
        }
    }

    &-Wrapper {
        tr {
            &:first-child {
                th,
                td {
                    padding-top: 20px;
                    padding-bottom: 10px;

                    @include desktop {
                        padding-bottom: 25px;
                    }
                }
            }

            th,
            td {
                padding: 0;
                padding-bottom: 15px;

                @include h6;
            }

            td {
                text-align: right;
            }

            th {
                @include desktop {
                    text-align: right;
                }
            }
        }
    }
}
