/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountOrder {
    &-Contentsection,
    &-InfoSection {
        background: var(--my-account-content-background);
        padding: 20px 10px 0 10px;

        @include desktop {
            padding: 20px;
            border-radius: 5px;
        }
    }

    &-InfoSection {
        margin-top: 20px;
    }

    &-BaseInfo {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 25px;
        flex-direction: column;

        @include desktop {
            align-items: center;
            flex-direction: row;
        }

        .MyAccountOrder-Reorder {
            display: none;

            @include desktop {
                display: block;
            }
        }

        a {
            display: flex;

            @include order-return-icon;

            @include print-only {
                display: none;
            }

            &::before {
                margin-right: 5px;
            }

            color: var(--my-account-order-subheading-color);
            font-weight: $font-weight-medium;
            letter-spacing: $letter-spacing;
            font-size: 15px;
            line-height: 19px;
            margin-top: 25px;

            &:hover {
                color: var(--link-color);

                &::before {
                    color: var(--link-color);
                }
            }
        }
    }

    &-ReorderMob {
        @include mobile {
            padding-bottom: 20px;
        }

        @include desktop {
            display: none;
        }

        @include print-only {
            display: none;
        }
    }

    &-CreationDate {
        font-size: 14px;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 20px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing1;
        line-height: 22px;
        order: -1;

        @include desktop {
            margin-top: 20px;
            order: 0;
            margin-bottom: 0;
        }
    }

    &-OrderStatus {
        display: inline-block;
        border-radius: 16px;
        border: var(--my-account-order-subheading-border);
        color: var(--my-account-order-subheading-color);
        font-size: 12px;
        font-weight: $font-weight-medium;
        letter-spacing: $letter-spacing1;
        line-height: 12px;
        padding: 6px 15px;
        text-transform: uppercase;
        margin-right: auto;

        @include desktop {
            display: inline-block;
            margin-left: 15px;
        }
    }

    &-OrderId {
        margin-bottom: 0;
        width: 100%;
        order: -1;

        @include desktop {
            width: auto;
            order: 0;
        }
    }

    &-Buttons {
        display: flex;
        margin-bottom: 25px;

        &:empty {
            display: none;
        }
    }

    &-Reorder {
        @include print-only {
            display: none;
        }
    }

    &-SubscribeToStatus {
        font-weight: normal;
        width: max-content;

        &:hover {
            text-decoration: none;
            color: var(--link-hover);
        }

        &:focus {
            color: var(--primary-base-color);
            text-decoration: none;
        }
    }

    &-PrintOrder {
        display: none;

        @include desktop {
            display: block;
            margin-inline-start: auto;
        }
    }

    &-Address,
    &-Products {
        margin-block-end: 14px;

        @include desktop {
            margin-block-start: 24px;
        }

        @include mobile {
            margin-block-end: 28px;
            /* stylelint-disable-next-line declaration-no-important */
            overflow-x: unset!important;
        }
    }

    &-Products {
        width: 100%;
        margin-block-start: 12px;
        table-layout: initial;

        @include mobile {
            margin-block-start: 14px;
        }

        td,
        th {
            vertical-align: middle;
        }
    }

    &-Thumbnail {
        width: 60px;
        height: 60px;
        padding: 0;
    }

    &-Row {
        height: 80px;
        padding: 10px 0;

        &:nth-child(even) {
            img {
                mix-blend-mode: multiply;
            }
        }
    }

    &-Content {
        min-height: 200px;

        @include desktop {
            display: grid;
            grid-gap: 24px;
        }
    }

    h4 {
        font-size: 14px;

        @include mobile {
            font-size: 17px;
        }
    }

    dt {
        font-weight: bold;
    }

    dl {
        display: grid;
        margin-block-start: 12px;
        grid-template-columns: 1fr 1fr;
        grid-gap: 6px;

        @include mobile {
            margin-block-start: 14px;
            grid-gap: 7px;
            margin-block-end: 28px;
        }
    }

    td,
    th {
        min-width: 0;
    }

    &-Address {
        .KeyValueTable-Wrapper {
            overflow-x: unset;
        }
    }

    &-Comments {
        margin-bottom: 20px;

        @include mobile {
            margin-block-end: 25px;
        }

        &Title {
            @include h5;

            margin-bottom: 15px;
        }
    }

    &-Comment {
        dt,
        dd {
            @include paragraph-text;
        }

        dt {
            margin-right: 15px;
        }
    }
}

.MyAccount-TabContent {
    &.MyAccount-TabContent_activeTab_my-orders {
        background: none;
        padding: 0;
    }
}

.MyAccountOrderTableRow {
    &-CancelButton {
        @include paragraph-text;

        color: var(--secondary-dark-color);
        text-decoration: underline;
        font-weight: $font-weight-medium;
        cursor: pointer;
    }
}

@media print {
    .Header-Wrapper_name_customer_order,
    .Footer,
    .Breadcrumbs_isMyAccount,
    .MyAccountOrder-Reorder,
    .MyAccountOrderTabs-Wrapper,
    #launcher,
    .MyAccountOrderItemsTable-Print {
        display: none;
    }

    .MyAccount {
        .MyAccountTabList {
            display: none;
        }

        .MyAccountOrderItemsTableRow-ProductImage {
            .Image_ratio_square {
                padding-bottom: 50px;
            }
        }
    }
}
