/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountMyOrders {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr;

    &-Title {
        @include mobile {
            display: none;
        }
    }

    &-Button {
        @include desktop {
            position: absolute;
            inset-block-start: -55px;
            inset-inline-end: 0;
        }
    }

    &-NoOrders {
        p {
            margin-bottom: 0;
        }
    }

    &-Table {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        border: 0;

        @include desktop {
            overflow-x: auto;
            margin-bottom: 0;
        }

        @include desktop-xl {
            overflow: inherit;
        }

        &.MyDownloadable {
            width: auto;
            max-width: initial;
        }

        .KeyValueTable {
            margin-bottom: 0;

            &-HeadingContainer {
                @include mobile {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @include desktop {
                    width: 800px;
                }

                @include desktop-xl {
                    width: 100%;
                }
            }

            &-Heading {
                @include mobile {
                    width: 33.33%;
                }

                @include desktop {
                    width: 15%;
                }

                &:nth-child(3) {
                    @include desktop {
                        width: 20%;
                    }
                }

                &:nth-child(4) {
                    @include desktop {
                        width: 25%;
                    }
                }

                &:nth-child(5) {
                    width: 12%;
                }
            }

            &-Content {
                @include mobile {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @include desktop {
                    width: 800px;
                }

                @include desktop-xl {
                    width: 100%;
                }
            }

            &-Column {
                min-width: inherit;
                width: inherit;

                @include mobile {
                    width: 33.33%;
                    padding-right: 0;
                }

                @include desktop {
                    width: 15%;
                }

                &:first-of-type {
                    color: var(--primary-base-color);
                    font-weight: $font-weight-regular;
                    letter-spacing: $letter-spacing2;

                    @include mobile {
                        width: 33.33%;
                        padding-right: 10px;
                    }
                }

                &:nth-child(3) {
                    @include desktop {
                        width: 20%;
                    }
                }

                &:nth-child(4) {
                    @include desktop {
                        width: 25%;
                    }
                }

                &:nth-child(5) {
                    width: 12%;
                }

                &:last-of-type {
                    text-align: right;
                    padding-right: 0;

                    @include mobile {
                        width: 33.33%;
                        padding-right: 0;
                    }
                }
            }
        }

        + nav {
            .Pagination {
                display: flex;
                
                @include mobile {
                    padding-bottom: 20px;
                }

                @include desktop {
                    margin-top: 20px;
                }
            }
        }
    }
}
