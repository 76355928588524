/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrderItemsTable {
    &-OrderTitle {
        @include h5;

        margin-bottom: 20px;

        &.MyAccountOrderItemsTable-ReturnTitle {
            + .MyAccountOrderItemsTable-Print {
                display: none;
            }
        }
    }

    &-Headings {
        @include mobile {
            display: none;
        }

        &.KeyValueTable {
            &-Row {
                @include print-only {
                    display: flex !important;
                    flex-wrap: wrap !important;
                }
            }
        }
    }

    &-TableRows {
        @include desktop {
            border-left: var(--table-body-border);
            border-right: var(--table-body-border);
            border-bottom: var(--table-body-border);
        }
    }

    &-Products {
        width: 100%;
        border: 0;
        margin-bottom: 0;

        thead {
            @include desktop {
                border-left: var(--primary-divider-color);
                border-right: var(--primary-divider-color);
            }

            tr {
                th, td {
                    &:nth-child(n+3) {
                        text-align: end;
                    }
                }
            }
        }

        tbody {
            tr {
                border-block-start: var(--table-body-border);

                &:first-of-type {
                    border-block-start: none;
                }


                &:not(:last-child) {
                    border-block-end: none;
                }
            }

            .MyAccountOrderItemsTableRow {
                &-Row {
                    @include mobile {
                        border-block: unset;
                    }
                }

                &-EnteredLabel,
                &-EnteredRow {
                    border-block: unset;
                }

                @include mobile {
                    &-Name {
                        border-block: unset;
                    }
                }
            }

            @include mobile {
                tr {
                    td {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-ProductsWrapper {
        .KeyValueTable {
            &-HeadingContainer {
                @include mobile {
                    display: none;
                }

                @include print-only {
                    display: flex;
                }
            }

            &-Heading {
                &.MyAccountOrderItemsTable {
                    &-Name {
                        @include desktop {
                            width: 35%;
                        }

                        @include print-only {
                            width: 40% !important;
                        }
                    }
                    
                    &-ProductSku {
                        @include desktop {
                            width: 20%;
                        }

                        @include print-only {
                            width: 15% !important;
                        }
                    }

                    &-Quantity,
                    &-Subtotal,
                    &-Price {
                        width: 15%;
                
                        @include desktop {
                            text-align: right;
                        }

                        @include print-only {
                            width: 15% !important;
                        }
                    }

                    &-Subtotal {
                        @include print-only {
                            padding-left: 10px;
                        }
                    }
                }
            }

            &-Content {
                @include mobile {
                    border-left: 0;
                    border-right: 0;
                    padding-left: 0;
                    padding-right: 0;
                }

                &.KeyValueTable-MobileTable {
                    @include print-only {
                        flex-wrap: inherit !important;
                    }

                    .KeyValueTable {
                        &-Row {
                            &.MyAccountOrderItemsTableRow-Row {
                                @include print-only {
                                    border-bottom: 0;
                                }
    
                                &:nth-child(1) {
                                    @include print-only {
                                        width: 40%;
                                    }
                                }
    
                                &:nth-child(2) {
                                    @include print-only {
                                        width: 15%;
                                    }
                                }
    
                                &:nth-child(3),
                                &:nth-child(4),
                                &:nth-child(5) {
                                    @include print-only {
                                        width: 15%;
                                    }
                                }
    
                                .KeyValueTable-Column {
                                    @include print-only {
                                        padding-top: 20px;
                                        padding-right: 15px;
                                        border-bottom: 0;
                                        width: 100%;
                                    }
    
                                    strong {
                                        &:first-of-type {
                                            @include print-only {
                                                display: none;
                                            }
                                        }
                                    }
    
                                    .MyAccountOrderItemsTableRow-NameAndOptions {
                                        @include print-only {
                                            width: auto;
                                            flex-wrap: inherit;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .KeyValueTable {
                    &-Row {
                        @include mobile {
                            border-bottom: 0;
                        }

                        &:not(:first-child) {
                            .KeyValueTable-Column {
                                @include mobile {
                                    padding-top: 0;
                                }
                            }
                        }
                    }

                    &-Column {
                        dl {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            padding-left: 65px;
        
                            @include mobile {
                                padding-left: 80px;
                                margin-bottom: 10px;
                            }
        
                            &:last-child {
                                @include mobile {
                                    margin-bottom: 0;
                                }
                            }
        
                            dt,
                            dd {
                                width: 100%;
                                font-size: 12px;
                                color: var(--color-gray2);
                                letter-spacing: $letter-spacing1;

                                @include mobile {
                                    margin-bottom: 0;
                                }
        
                                strong {
                                    font-size: 12px;
                                    color: var(--secondary-light-color);
                                    letter-spacing: $letter-spacing1;
                                    font-weight: $font-weight-medium;
                                }
                            }
        
                            dt {
                                padding-top: 0;
                            }
                        }

                        strong {
                            @include mobile {
                                font-weight: $font-weight-medium;
                                min-width: 75px;
                                padding-right: 10px;
                            }
                        }
                    }
                }

                .MyAccountOrderItemsTableRow-Options {
                    @include mobile {
                        width: 100%;
                    }

                    .KeyValueTable-Column {
                        @include mobile {
                            padding-top: 0;
                            margin-top: -20px;
                        }
                    }
                }
            }
    
            &-Column {
                min-width: inherit;
                width: inherit;

                @include mobile {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                }

                > a {
                    --link-color: var(--secondary-dark-color);

                    text-decoration: underline;
                }

                &:last-of-type {
                    padding-right: 0;
                }

                &.MyAccountOrderItemsTable {
                    &-Name {
                        @include desktop {
                            width: 35%;
                        }

                        @include print-only {
                            width: 40%;
                        }
                    }
                    
                    &-ProductSku {
                        @include desktop {
                            width: 20%;
                        }

                        @include print-only {
                            width: 15%;
                        }
                    }

                    &-Qty,
                    &-Subtotal,
                    &-Price {
                        width: 100%;
                
                        @include desktop {
                            text-align: right;
                            width: 15%;
                        }

                        @include print-only {
                            width: 15%;
                        }
                    }

                    &-Price {
                        strong {
                            @include h6;
                        }
                    }
                }
            }

            &-Footer {
                @include desktop {
                    padding-left: 20px;
                    padding-right: 20px;
                    border-left: var(--table-body-border);
                    border-right: var(--table-body-border);
                    border-bottom: var(--table-body-border);
                    margin-top: -1px;
                    background: var(--body-background-color);
                }

                .KeyValueTable-Row {
                    @include desktop {
                        border-top: var(--table-body-border);
                    }
                }
            }

            .MyAccountOrderItemsTableRow-ReturnMobileItemsTable {
                .KeyValueTable {
                    &-Row {
                        flex-wrap: wrap;
                    }

                    &-Column {
                        width: 50%;
                        padding-top: 0;
                        padding-bottom: 15px;

                        &:nth-child(1),
                        &:nth-child(2) {
                            padding-top: 20px;
                        }
                    }
                }
            }
        }

        .GiftWrapper {
            &-GiftWrapperDetails {
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 75px;

                @include desktop {
                    padding-left: 65px;
                }
            }

            &-WrapItem {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .Image {
                    border: 0.5px solid var(--secondary-divider-color);
                    width: 30px;
                    height: 30px;
                    padding-bottom: 28px;

                    &-Image {
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            &-WrapDetails {
                padding-left: 10px;
                display: flex;
                width: calc(100% - 30px);
            }

            &-WrapTitle {
                width: 100%;
                font-size: 12px;
                line-height: 15px;
                color: var(--secondary-light-color);
                font-weight: $font-weight-medium;
                letter-spacing: $letter-spacing1;
            }

            &-WrapName {
                width: 100%;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: $letter-spacing;
                color: var(--color-gray2);
            }

            &-Wrap,
            &-WrapMessage {
                width: 100%;
                display: flex;
                align-items: center;
            }

            &-WrapMessage {
                margin-top: 10px;
            }
        }

        &_RemoveSpaceClass {
            &_Return {
                .MyAccountOrderItemsTable {
                    &-Products {
                        .KeyValueTable {
                            &-Row {
                                @include mobile {
                                    flex-wrap: wrap;
                                }

                                .KeyValueTable-Row {
                                    @include mobile {
                                        border-bottom: 0;
                                    }

                                    &:first-of-type {
                                        @include mobile {
                                            padding-top: 20px;
                                        }
                                    }

                                    &:last-of-type {
                                        .KeyValueTable-Column {
                                            @include mobile {
                                                padding-bottom: 20px;
                                            }
                                        }
                                    }
                                }
                            }

                            &-Column {
                                @include mobile {
                                    width: 100%;
                                    display: flex;
                                    flex-wrap: wrap;
                                    padding-top: 0;
                                    padding-bottom: 15px;
                                }
                                
                                strong {
                                    @include mobile {
                                        min-width: 112px;
                                        padding-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-Name {
        strong {
            font-weight: $font-weight-medium;
        }
    }

    @include mobile {
        table {
            tbody {
                tr, td {
                    border-bottom: none;
                }
            }
        }
    }

    &-Quantity,
    &-Subtotal,
    &-Price {
        width: 15%;

        @include desktop {
            padding-left: 0;
            padding-right: 20px;
            text-align: right;
        }
    }
    
    &-Comments {
        &Title {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.1;
            margin-block-end: 10px;
            margin-block-start: 20px;
        }
    }

    &-Comment {
        margin-block-end: 20px;

        dt {
            font-weight: bold;
            margin-block-end: 5px;
            margin-inline-start: 10px;
        }

        dd {
            margin-block-end: 10px;
            margin-inline-start: 10px;
        }
    }

    &-TrackShipmentButton {
        color: var(--my-account-order-subheading-color);
        font-weight: $font-weight-medium;
        letter-spacing: $letter-spacing;
        font-size: 15px;
        line-height: 19px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-top: 15px;

        @include print-only {
            display: none;
        }

        &::before {
            background: url(./images/shipping-track.svg) no-repeat;
            margin-right: 5px;
            background-size: contain;
            width: 20px;
            height: 20px;
            content: '';
            display: inline-block;
            margin-top: 2px;
        }

        &:hover {
            color: var(--link-color);

            &::before {
                filter: invert(44%) sepia(74%) saturate(5024%) hue-rotate(359deg) brightness(98%) contrast(101%);
            }
        }
    }

    &-Print {
        color: var(--my-account-order-subheading-color);
        font-weight: $font-weight-medium;
        letter-spacing: $letter-spacing;
        font-size: 15px;
        line-height: 19px;
        cursor: pointer;

        @include print-icon;

        &::before {
            margin-right: 5px;
        }

        &:hover {
            color: var(--link-color);

            &::before {
                color: var(--link-color);
            }
        }
    }

    &-TitleWrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid var(--color-gray3);
        margin-top: 30px;

        @include desktop {
            border: 0;
            margin-top: 0;
        }
    }
}
