/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

.MyAccountOrderItemsTableRow {
    &-Qty {
        display: table-cell;
        vertical-align: top;

        span {
            margin-block-end: 5px;
        }
    }

    &-QtyList {
        display: inline-block;

        li {
            color: var(--primary-light-color);
            font-weight: $font-weight-regular;

            &::before {
                content: none;
            }

            &:last-child {
                margin-block-end: 0;
            }
        }
    }

    &-RowWrapper {
        font-size: 14px;

        td {
            padding-block-start: 16px;
            padding-block-end: 15px;
            padding-inline: 10px;
            vertical-align: top;

            @include desktop {
                padding-left: 20px;
                padding-right: 0;
            }

            &:first-child {
                @include desktop {
                    display: flex;
                    flex-wrap: wrap;
                }

                dl {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    @include mobile {
                        margin-bottom: 15px;
                    }

                    &:last-child {
                        @include mobile {
                            margin-bottom: 0;
                        }
                    }

                    @include desktop {
                        padding-left: 65px
                    }

                    dt,
                    dd {
                        @include mobile {
                            margin-bottom: 0;
                        }

                        @include desktop {
                            width: 100%;
                            font-size: 12px;
                            color: var(--color-gray2);
                            letter-spacing: $letter-spacing1;
                        }

                        strong {
                            @include desktop {
                                font-size: 12px;
                                color: var(--secondary-light-color);
                                letter-spacing: $letter-spacing1;
                            }
                        }
                    }

                    dt {
                        padding-top: 0;
                    }
                }
            }

            strong {
                @include h6;

                @include mobile {
                    line-height: 22px;
                    min-width: 75px;
                    padding-right: 10px;
                    display: inline-block;
                }

                + strong {
                    @include mobile {
                        @include paragraph-text;

                        color: var(--primary-light-color);
                    }
                }
            }

            &:nth-child(n+3) {
                text-align: end;
            }

            dl {
                dt {
                    padding-block-start: 15px;
                    line-height: 15px;
                }

                dd {
                    line-height: 15px;
                }
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                @include desktop {
                    padding-right: 20px;
                    padding-left: 0;
                    text-align: right;
                }
            }
        }

        tr {
            &:first-child {
                td:first-child {
                    padding-top: 20px;
                }
            }

            &:last-child {
                td:last-child {
                    padding-bottom: 20px;
                }
            }
        }
    }

    &-Row {
        @include mobile {
            td {
                padding: 0 10px 15px;

                strong {
                    vertical-align: top;
                    margin-inline-end: 15px;

                    &:first-child {
                        &::after {
                            content: ":";
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    &-NameAndOptions {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .MyAccountOrderItemsTableRow-Row {
            @include mobile {
                display: none
            }
        }
    }

    &-Name {
        @include h6;

        padding-left: 20px;
        width: calc(100% - 55px);

        @include desktop {
            width: calc(100% - 65px);
            padding-bottom: 20px;
            padding-left: 10px;
        }
        
        @include print-only {
            margin-bottom: 20px;
        }
    }

    &-ProductImage {
        height: 55px;

        .Image {
            width: 55px;
            height: 55px;
            background-color: var(--color-gray5);

            @include mobile {
                padding-bottom: 0;
            }
        }
    }

    &-EnteredRow {
        td {
            padding-block-start: 0;
            padding-block-end: 5px;
            padding-inline: 10px;

            &:nth-child(n+3) {
                text-align: end;
            }

            &:first-of-type {
                padding-inline-start: 20px;
            }
        }

        &_isLastOptionItem {
            td {
                padding-block-end: 15px;
            }
        }
    }

    &-EnteredLabel {
        td {
            padding: 10px;

            @include desktop {
                padding: 5px 20px;
            }
        }
    }

    &-OptionLabel {
        &:first-child {
            margin-block-start: 0;
        }

        margin-block-start: 15px;
        margin-block-end: 5px;
    }

    &-OptionValue {
        margin-block-end: 5px;
    }
}
