/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-store-credit
 * @author ashangunawardena
 */

:root {
    --my-account-store-credit-background: var(--color-white);
    --my-account-store-credit-balance-desktop-width: 300px;
}

.MyAccountStoreCredit {
    background: var(--my-account-store-credit-background);
    padding: 20px 10px;

    @include desktop {
        padding: 20px;
        border-radius: 5px;
    }

    &-Heading {
        @include mobile {
            display: none;
        }
    }

    &-BalanceCard {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 6px;

        @include mobile {
            margin-bottom: 20px;
        }

        @include desktop {
            max-width: 340px;
            margin-bottom: 30px;
        }

        &Container {
            position: absolute;
            padding-left: 25px;
        }
    }

    &-BalanceTitle {
        line-height: 20px;
        color: var(--color-white);
        margin-bottom: 10px;
    }

    &-BalanceAmount {
        font-size: 40px;
        line-height: 49px;
        font-weight: $font-weight-semibold;
        color: var(--color-white);

        @include desktop {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: var(--my-account-store-credit-balance-desktop-width);
        }
    }

    &-RedeemTitle {
        @include paragraph-text;
    }

    &-BalanceHistoryTitle {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    &-Table {
        width: 100%;
        margin-bottom: 0;

        .MyAccountStoreCredit {
            &-ActionColumn {
                @include desktop {
                    width: 20%;
                }
            }

            &-BalanceUpdateColumn,
            &-BalanceColumn {
                @include desktop {
                    width: 30%;
                }
            }
            
            &-DateColumn {
                @include desktop {
                    width: 20%;
                    padding-right: 0;
                }  
            }

            &-TableRow {
                .KeyValueTable {
                    &-Column {
                        min-width: inherit;
                    }
                }
            }
        }
    }

    &-MobileTable {
        .KeyValueTable {
            &-Row {
                flex-wrap: wrap;
                padding-top: 20px;

                &:first-of-type {
                    padding-top: 0;
                }
            }

            &-Column {
                display: flex;
                min-width: 100%;
                padding: 0 15px 15px 0;
                color: var(--primary-light-color);

                strong {
                    width: 80px;
                    padding-right: 15px;
                    font-weight: $font-weight-medium;
                }
            }
        }
    }
}
