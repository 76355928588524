/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressPopup {
    font-size: 12px;

    .Popup {
        &-Header {
            @include desktop {
                padding: 0 20px;
            }
        }

        &-CloseBtn {
            @include desktop {
                top: 0;
                right: 20px;
            }
        }

        &-Content {
            @include desktop {
                max-width: 360px;
                max-height: 583px;
                padding-left: 0;
                padding-right: 0;
                overflow: inherit;
            }
    
            @include mobile {
                padding-top: 0;
                padding-bottom: 30px;
            }
        }
    }

    &-Address {
        margin: 12px 0;

        @include mobile {
            margin: 14px 0;
        }
    }

    &-AddressForm {
        @include desktop {
            @include custom-scroll;

            max-height: 534px;
            overflow: hidden;
            overflow-y: auto;
            padding: 0 20px;
        }

        .Form {
            @include desktop {
                padding-bottom: 20px;
            }
        }
    }

    .MyAccount-Button {
        width: 100%;
    }

    .Button {
        @include mobile {
            width: 100%;
        }
    }

    .Loader {
        &-Scale {
            position: fixed;
        }
    }

    .FieldForm-Fields {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .Field {
        &-Wrapper {
            width: 100%;

            &:nth-child(3) {
                margin-top: 20px;
            }

            &_type_checkbox {
                order: 4;
                margin-top: 15px;

                &:first-of-type {
                    margin-top: 25px;
                }
            }
        }

        &Group-Wrapper {
            width: 100%;
        }
    }
}
