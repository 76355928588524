/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountOrderInformation {
    &-Wrapper {
        margin-top: 0;
    }

    &-Information {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }
    }

    &-Title {
        border-bottom: 1px solid var(--color-gray3);
        margin-bottom: 20px;

        h2 {
            margin-bottom: 20px;
        }
    }

    &-AddressWrapper {
        margin-block-start: 20px;
    }

    &-Address {
        .KeyValueTable {
            width: 90%;
            border: 0;
            margin-bottom: 0;

            &-Wrapper {
                width: 90%;
            }

            &-Content {
                padding: 0;
                border: 0;
            }

            &-Row {
                display: inline-block;
                width: auto;
                border-bottom: 0;

                &:hover {
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    width: auto;
                }

                .KeyValueTable {
                    &-Column {
                        min-width: auto;
                        padding: 0;
                        padding-right: 5px;

                        @include print-only {
                            border-bottom: 0;
                            margin-bottom: 0;
                        }

                        &:first-of-type {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &-Column {
        flex: 1 1 0;
        font-size: 14px;
        margin-bottom: 30px;
        color: var(--body-paragraph-color);

        @include desktop {
            margin-bottom: 0;
        }

        @include print-only {
            width: 50%;
        }

        &:last-of-type {
            @include mobile {
                margin-bottom: 20px;
            }
        }

        &_type {
            &_billingAddress,
            &_shippingAddress {
                margin-inline-end: 25px;
            }
        }
    }

    &-ColumnTitle {
        strong {
            @include h6;
        }

        margin-bottom: 15px;
    }

    &-PaymentMethod {
        display: flex;
        flex-direction: column;

        span:not(:last-child) {
            margin-block-end: 10px;
        }
    }
}
