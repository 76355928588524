/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --star-size: 18px;
    --plus-minus-icon-size: 20px;
    --wishlist-heart-size: 25px;
}

@mixin icon-black-hover-effect {
    fill: var(--color-black);

    &:hover {
        fill: var(--primary-base-color);
    }
}

@mixin icon-primary {
    fill: var(--primary-base-color);

    &:hover {
        fill: var(--primary-dark-color);
    }
}

@mixin not-available {
    content: '';
    position: absolute;
    width: 1px;
    height: 32px;
    transform-origin: 50% 50%;
    inset-inline-start: 15px;
    inset-block-start: 1px;
    transform: rotate(45deg);
    box-shadow: none;
    border-radius: 0;
    opacity: 1;
}

@mixin ios-share {
    $color-dodger-blue: #3b9cfe;

    border: 2px solid $color-dodger-blue;
    inset-block-end: -3px;
    height: 18px;
    margin: 0 4px;
    width: 18px;

    &::before {
        content: '';
        border-inline-start: 2px solid $color-dodger-blue;
        border-block-start: 2px solid $color-dodger-blue;
        display: block;
        height: 6px;
        inset-inline-start: 3px;
        position: relative;
        inset-block-start: -9px;
        transform: rotate(45deg);
        width: 6px;
    }

    &::after {
        border-inline-start: 2px solid $color-dodger-blue;
        content: '';
        display: block;
        height: 16px;
        inset-inline-start: 6px;
        position: relative;
        inset-block-start: -16px;
        width: 19px;
    }
}

@mixin ios-plus {
    $color-storm-grey: #7b7b86;

    background: linear-gradient($white, $white),
        linear-gradient($white, $white),
        $color-storm-grey;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 52% 1px, 1px 48%;
    border-radius: 5px;
    height: 20px;
    margin: 0 4px;
    width: 20px;
}

@mixin rtl-icon {
    [dir="rtl"] & {
        transform: scaleX(-1);
    }
}

// Theme Icons

@mixin instagram-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e901";
        font-size: 20px;
        line-height: 20px;
        color: var(--primary-base-color);
        font-weight: normal;
    }
}

@mixin arrow-down-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e937";
        font-size: 5px;
        line-height: 5px;
        color: var(--color-gray9);
        font-weight: normal;
    }
}

@mixin arrow-up-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e938";
        font-size: 5px;
        line-height: 5px;
        color: var(--color-gray9);
        font-weight: normal;
    }
}

@mixin arrow-down-large-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e937";
        font-size: 8px;
        line-height: 9px;
        color: var(--primary-light-color);
        font-weight: normal;
    }
}

@mixin arrow-up-large-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e938";
        font-size: 8px;
        line-height: 9px;
        color: var(--primary-light-color);
        font-weight: normal;
    }
}

@mixin arrow-down-thin-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e917";
        font-size: 15px;
        line-height: 15px;
        color: var(--primary-light-color);
        font-weight: $font-weight-bold;
    }
}

@mixin arrow-up-thin-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e915";
        font-size: 15px;
        line-height: 15px;
        color: var(--primary-light-color);
        font-weight: $font-weight-bold;
    }
}

@mixin account-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92f";
        font-size: 20px;
        line-height: 20px;
        color: var(--color-gray9);
        font-weight: normal;
    }
}

@mixin wishlist-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e932";
        font-size: 20px;
        line-height: 20px;
        color: var(--color-gray9);
        font-weight: normal;
    }
}

@mixin wishlist-icon-active {
    &::before {
        font-family: $icomoon-font-family;
        content: "\f004";
        font-size: 20px;
        line-height: 20px;
        color: var(--primary-base-color);
        font-weight: normal;
    }
}

@mixin minicart-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e930";
        font-size: 20px;
        line-height: 20px;
        color: var(--color-gray9);
        font-weight: normal;
    }
}

@mixin search-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90d";
        font-size: 20px;
        line-height: 20px;
        color: var(--color-gray9);
        font-weight: normal;
    }
}

@mixin close-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90e";
        font-size: 20px;
        line-height: 20px;
        color: var(--color-gray9);
        font-weight: normal;
    }
}

@mixin close-large-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e913";
        font-size: 12px;
        line-height: 12px;
        color: var(--color-gray9);
        font-weight: normal;
    }
}

@mixin plus-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92b";
        font-size: 8px;
        line-height: 8px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin minus-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92a";
        font-size: 1.49px;
        line-height: 1.5px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin lock-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90b";
        font-size: 18px;
        line-height: 19px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin faq-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e928";
        font-size: 19px;
        line-height: 19px;
        color: var(--primary-light-color);
        font-weight: normal;
    }
}

@mixin email-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e926";
        font-size: 18px;
        line-height: 19px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin chat-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e929";
        font-size: 20px;
        line-height: 19px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin footer-show-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e917";
        font-size: 18px;
        line-height: 19px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin footer-hide-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e915";
        font-size: 18px;
        line-height: 19px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin home-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e936";
        font-size: 23px;
        line-height: 23px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin menu-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e935";
        font-size: 23px;
        line-height: 23px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin footer-facebook-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e904";
        font-size: 20px;
        line-height: 19px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin footer-instagram-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e901";
        font-size: 20px;
        line-height: 19px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin footer-twitter-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90a";
        font-size: 18px;
        line-height: 19px;
        color: var(--color-white);
        font-weight: normal;
    }
}

@mixin arrow-right-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e90f";
        font-size: 16px;
        line-height: 16px;
        color: var(--primary-light-color);
        font-weight: $font-weight-semibold;
    }
}

@mixin arrow-left-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e912";
        font-size: 15px;
        line-height: 15px;
        color: var(--primary-light-color);
        font-weight: $font-weight-semibold;
    }
}

@mixin arrow-right-thin-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e90f";
        font-size: 25px;
        line-height: 25px;
        color: var(--primary-light-color);
    }
}

@mixin arrow-left-thin-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e912";
        font-size: 25px;
        line-height: 25px;
        color: var(--primary-light-color);
    }
}

@mixin back-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e912";
        font-size: 15px;
        line-height: 15px;
        color: var(--primary-light-color);
        font-weight: $font-weight-semibold;
    }
}

@mixin next-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90f";
        font-size: 15px;
        line-height: 15px;
        color: var(--primary-light-color);
        font-weight: $font-weight-semibold;
    }
}

@mixin share-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e918";
        font-size: 18px;
        line-height: 19px;
        color: var(--color-gray9);
        font-weight: normal;
        margin: 0 10px 0 0;
    }
}

@mixin share-facebook-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e904";
        font-size: 15px;
        line-height: 19px;
        color: var(--primary-light-color);
        font-weight: normal;
    }
}

@mixin share-twitter-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90a";
        font-size: 13px;
        line-height: 19px;
        color: var(--primary-light-color);
        font-weight: normal;
    }
}

@mixin share-email-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e926";
        font-size: 13px;
        line-height: 19px;
        color: var(--primary-light-color);
        font-weight: 600;
    }
}

@mixin share-wa-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92c";
        font-size: 13px;
        line-height: 19px;
        color: var(--primary-light-color);
        font-weight: 600;
    }
}

@mixin gallery-next-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e917";
        font-size: 24px;
        line-height: 15px;
        color: var(--primary-light-color);
        font-weight: $font-weight-regular;
    }
}

@mixin gallery-pre-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e915";
        font-size: 24px;
        line-height: 15px;
        color: var(--primary-light-color);
        font-weight: $font-weight-regular;
    }
}

@mixin filter-checked-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e919";
        font-size: 8px;
        line-height: 14px;
        color: var(--primary-light-color);
    }
}

@mixin sort-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e93c";
        font-size: 12px;
        line-height: 14px;
        color: var(--primary-light-color);
    }
}

@mixin filters-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e924";
        font-size: 15px;
        line-height: 15px;
        color: var(--primary-light-color);
    }
}

@mixin discount-code-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e949";
        font-size: 14px;
        line-height: 15px;
        color: var(--color-gray2);
    }
}

@mixin gift-card-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e947";
        font-size: 14px;
        line-height: 15px;
        color: var(--color-gray2);
    }
}

@mixin cashback-point-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94d";
        font-size: 18px;
        line-height: 18px;
        color: var(--color-gray2);
    }
}

@mixin date-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e948";
        font-size: 15px;
        line-height: 15px;
        color: var(--primary-light-color);
    }
}

@mixin faq-plus {
    &::after {
        font-family: $icomoon-font-family;
        content: "+";
        font-size: 32px;
        line-height: 5px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin faq-minus {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e92a";
        font-size: 3px;
        line-height: 5px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin myaccount-next-nav {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e90f";
        font-size: 15px;
        line-height: 5px;
        font-weight: normal;
        color: var(--secondary-dark-color);
    }
}

@mixin checkmark-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e931";
        font-size: 15px;
        line-height: 15px;
        font-weight: normal;
        color: var(--primary-base-color);
    }
}

@mixin remove-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e922";
        font-size: 15px;
        line-height: 25px;
        font-weight: normal;
        color: var(--secondary-dark-color);
    }
}

@mixin phone-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94a";
        font-size: 15px;
        line-height: 15px;
        font-weight: normal;
        color: var(--color-gray2);
    }
}

@mixin clock-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94b";
        font-size: 15px;
        line-height: 16px;
        font-weight: normal;
        color: var(--color-gray2);
    }
}

@mixin location-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e906";
        font-size: 14px;
        line-height: 14px;
        font-weight: normal;
        color: var(--color-gray2);
    }
}

@mixin shipping-step-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e927";
        font-size: 18px;
        line-height: 18px;
        font-weight: normal;
        color: var(--secondary-divider-color);
    }
}

@mixin billing-step-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92e";
        font-size: 15px;
        line-height: 18px;
        font-weight: normal;
        color: var(--secondary-divider-color);
    }
}

@mixin password-show-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e942";
        font-size: 12px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin password-hide-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e946";
        font-size: 19px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin gift-wrapper-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e925";
        font-size: 20px;
        line-height: 20px;
        font-weight: normal;
        color: var(--primary-dark-color);
    }
}

@mixin edit-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e923";
        font-size: 15px;
        line-height: 20px;
        font-weight: normal;
        color: var(--secondary-light-color);
    }
}

@mixin wishlist-cart-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94c";
        font-size: 19px;
        line-height: 20px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin print-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94e";
        font-size: 15px;
        line-height: 20px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin order-return-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94f";
        font-size: 20px;
        line-height: 20px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin track-shipment-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e950";
        font-size: 20px;
        line-height: 20px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin faq-plus-thin-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e952";
        font-size: 20px;
        line-height: 20px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin faq-minus-thin-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e951";
        font-size: 2.2px;
        line-height: 20px;
        font-weight: normal;
        color: var(--primary-light-color);
    }
}

@mixin qty-plus-thin-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e954";
        font-size: 9px;
        line-height: 9px;
        font-weight: normal;
        color: var(--color-white);
        margin-left: 1px;
    }
}

@mixin qty-minus-thin-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e953";
        font-size: 1px;
        line-height: 3px;
        font-weight: normal;
        color: var(--color-white);
        margin-left: 1px;
    }
}

@mixin apple-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e955";
        font-size: 16px;
        line-height: 16px;
        font-weight: normal;
        color: var(--secondary-light-color);
    }
}

@mixin ios-share-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e956";
        font-size: 12px;
        line-height: 14px;
        font-weight: normal;
        color: var(--secondary-light-color);
    }
}

// Variables

$icon-share-wishlist: "\e956";
$icon-apple: "\e955";
$icon-feather-minus: "\e953";
$icon-feather-plus: "\e954";
$icon-close-minus-thin: "\e951";
$icon-close-plus-thin: "\e952";
$icon-cashback-point: "\e94d";
$icon-phone-footer: "\e94a";
$icon-feather-clock: "\e94b";
$icon-delivery-info: "\e940";
$icon-membership-info: "\e941";
$icon-delivery-estimation: "\e943";
$icon-redeem-gift: "\e947";
$icon-discount-code: "\e949";
$icon-sort: "\e93c";
$icon-angle-down: "\e937";
$icon-angle-up: "\e938";
$icon-instagram: "\e901";
$icon-lock: "\e90b";
$icon-search: "\e90d";
$icon-close: "\e90e";
$icon-arrow-right: "\e90f";
$icon-arrow-left: "\e912";
$icon-arrow-up: "\e915";
$icon-arrow-down: "\e917";
$icon-remove-gift-wrap: "\e922";
$icon-edit-gift-wrap: "\e923";
$icon-filter: "\e924";
$icon-gift-wrap: "\e925";
$icon-mail: "\e926";
$icon-help-faq: "\e928";
$icon-help-chat: "\e929";
$icon-account-mobile: "\e92f";
$icon-cart-mobile: "\e930";
$icon-wishlist-mobile: "\e932";
$icon-menu-mobile: "\e935";
$icon-home-mobile: "\e936";
$icon-contact-location: "\e945";
$icon-coupon: "\e93f";
$icon-filters: "\e907";
$icon-attachment: "\e911";
$icon-delete-filters: "\e913";
$icon-expand-filters: "\e916";
$icon-feather-left: "\e93d";
$icon-feather-right: "\e93e";
$icon-cart: "\e902";
$icon-location-picker: "\e906";
$icon-telephone: "\e914";
$icon-compare: "\e92d";
$icon-email: "\e933";
$icon-youtube: "\e934";
$icon-heart: "\f004";
$icon-star: "\f005";
$icon-star-o: "\f006";
$icon-heart-o: "\f08a";
$icon-calendar: "\e948";
$icon-toggle-off-normal: "\e946";
$icon-eye-reveal: "\e942";
$icon-hero-banner-left: "\e93a";
$icon-hero-banner-right: "\e93b";
$icon-star-rating: "\e939";
$icon-checkmark1: "\e931";
$icon-checkout-payment: "\e92e";
$icon-checkout-shipping: "\e927";
$icon-minus-large: "\e92a";
$icon-plus-large: "\e92b";
$icon-whatsapp: "\e92c";
$icon-send-email: "\e921";
$icon-minus-faq: "\e91f";
$icon-plus-faq: "\e920";
$icon-checkbox-checked: "\e91d";
$icon-checkbox: "\e91e";
$icon-radio-button-checked: "\e91b";
$icon-radio-button: "\e91c";
$icon-review-star: "\e91a";
$icon-filter-checked: "\e919";
$icon-share: "\e918";
$icon-information: "\e910";
$icon-copyright: "\e90c";
$icon-rss: "\e909";
$icon-twitter: "\e90a";
$icon-down-arrow: "\e903";
$icon-facebook: "\e904";
$icon-google: "\e905";
$icon-minus-icon: "\e908";
$icon-cart1: "\e900";
$icon-help: "\e623";
$icon-wishlist-full: "\e600";
$icon-wishlist-empty: "\e601";
$icon-next: "\e608";
$icon-menu: "\e609";
$icon-remove: "\e616";
$icon-prev: "\e617";
$icon-up: "\e621";
$icon-down: "\e622";
$icon-contact-phone: "\e944";
$icon-wishlist-cart: "\e94c";
$icon-print: "\e94e";
$icon-order-return: "\e94f";
$icon-track-shipment: "\e950";
