/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-cash-back-points
 * @author ashangunawardena
 */

:root {
    --my-account-cash-back-point-background: var(--color-white);
    --my-account-cash-back-point-card-desktop-width: 340px;
}
 
.MyAccountCashBackPoints {
    background: var(--my-account-cash-back-point-background);
    padding: 20px 10px;

    @include desktop {
        padding: 20px;
        border-radius: 5px;
    }

    &-Heading {
        @include mobile {
            display: none;
        }
    }

    &-TopContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &-BalanceCard {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 6px;

        @include desktop {
            max-width: var(--my-account-cash-back-point-card-desktop-width);
        }

        &Container {
            display: flex;
            align-items: center;
            position: absolute;
            padding-left: 25px;

            @include mobile {
                width: 100%;
                justify-content: space-between;
            }

            @include desktop {
                flex-wrap: wrap;
            }
        }
    }

    &-BalanceTitle {
        padding-right: 20px;

        @include desktop {
            max-width: 150px;
            padding-right: 10px;
        }

        h5 {
            color: var(--color-white);
            margin-bottom: 10px;
        }

        p {
            font-size: 11px;
            color: var(--color-white);
            margin-bottom: 0;
        }
    }

    &-BalanceAmount {
        font-size: 60px;
        line-height: 50px;
        font-weight: $font-weight-semibold;
        color: var(--color-white);
        padding-right: 10px;
        letter-spacing: -2px;

        @include desktop {
            width: calc(100% - 150px);
            text-align: center;
            word-break: break-all;
        }
    }

    &-ExchangeContainer {
        @include mobile {
            width: 100%;
            margin-top: 20px;
        }

        @include desktop {
            padding-left: 40px;
        }
    }

    &-ExchangeTitle {
        padding-bottom: 15px;
    }

    &-ExchangeAmount {
        margin-bottom: 0;
    }

    &-BalanceHistoryTitle {
        margin-top: 30px;
        margin-bottom: 20px;

        @include mobile {
            margin-top: 40px;
        }
    }

    &-Table {
        width: 100%;
        margin-bottom: 0;

        .CashBackPointsTable {
            &-BalanceColumn,
            &-AmountColumn {
                @include desktop {
                    width: 13%;
                }
            }

            &-PointsColumn {
                @include desktop {
                    width: 12%;
                }

                @include desktop-xl {
                    width: 10%;
                }

                @include desktop-xxl {
                    width: 12%;
                }
            }

            &-TransactionColumn {
                @include desktop {
                    width: 42%;
                }
            }

            &-DateColumn {
                @include desktop {
                    width: 20%;
                    padding-right: 0;
                }

                @include desktop-xl {
                    width: 22%;
                }

                @include desktop-xxl {
                    width: 20%;
                }
            }
        }

        .KeyValueTable {
            &-Heading,
            &-Column {
                min-width: auto;
            }
        }
    }

    &-RedeemingDescription {
        margin-top: 40px;

        h5 {
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 0;
        }
        
        .MyAccountOverlay {
            &-Checkbox {
                margin-top: 0;
                margin-bottom: 30px;

                .Field-CheckboxLabel {
                    padding-bottom: 0;
                }
            }
        }
    }

    &-MobileTable {
        .KeyValueTable {
            &-Row {
                flex-wrap: wrap;
                padding-top: 20px;

                &:first-of-type {
                    padding-top: 0;
                }

                &:last-of-type {
                    border-color: var(--secondary-divider-color);
                }
            }

            &-Column {
                display: flex;
                min-width: 100%;
                padding: 0 15px 15px 0;
                color: var(--primary-light-color);

                strong {
                    width: 106px;
                    padding-right: 15px;
                    font-weight: $font-weight-medium;
                }
            }
        }
    }
}
