/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.LoginAccount {
    .ContentWrapper {
        --content-wrapper-width: 1080px;

        padding-top: 1px;
        padding-block-end: 50px;
        padding-inline: 10px;

        @include desktop() {
            padding-inline: 20px;
            padding-top: 80px;
            padding-bottom: 90px;
            margin-top: -12px;
        }
    }

    &-CreateAccountButton {
        width: 100%;
    }

    &-InnerWrapper {
        @include desktop() {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
        }

        button {
            margin-top: 30px;
        }

        input {
            @include mobile {
                width: 100%;
            }

            @include desktop {
                width: 100%;
            }
        }

        > div {
            flex: 1;

            @include desktop {
                max-width: 510px;
                padding-top: 25px;
                padding-bottom: 25px;
            }
        }

        h3 {
            margin-bottom: 20px;
            text-align: center;
        }

        p {
            --body-paragraph-color: var(--color-gray2);

            font-size: 12px;
            line-height: 15px;
            letter-spacing: $letter-spacing;
            margin-block-end: 0;
        }
    }

    .Loader {
        margin: 0;
    }

    .Form {
        .Field {
            &-Wrapper {
                .Field_type_password {
                    &:first-of-type {
                        margin-top: 25px;
                    }
                }
            }
        }
    }

    &-SignInWrapper {
        @include desktop {
            padding: 0 90px 0 0;
            border-right: 1px solid var(--primary-divider-color);
        }
    }

    .MyAccountOverlay {
        &-Additional {
            margin-top: 30px;
            padding-top: 0;
            border-top: 0;

            @include desktop {
                display: inline-block;
            }

            button {
                margin-top: 0;

                @include desktop {
                    min-width: 198px;
                }
            }

            &_state_signIn {
                @include mobile {
                    margin-top: 20px;
                    padding-top: 20px;
                    border-top: 1px solid var(--primary-divider-color);
                }

                @include desktop {
                    display: none;
                }
            }
        }

        &-ForgotPassword {
            --button-color: var(--primary-base-color);
            --default-button-font-weight: 600;
            --default-button-letter-spacing: 0.53px;

            @include desktop {
                display: inline-block;
                width: auto;
                margin-left: 53px;
            }
        }
    }

    &-CreateAccount {
        @include mobile {
            display: none;
        }

        @include desktop {
            padding: 0 0 0 90px;
        }
    }

    &-CreateAccountWrapper {
        margin-block-start: 24px;
        display: flex;
        align-items: center;

        h4 {
            margin-block-end: 0;
        }

        button {
            width: auto;
            margin-inline-start: 5px;
        }
    }
}
