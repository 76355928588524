/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountDashboard {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
    background: var(--my-account-content-background);
    padding: 20px 10px;

    @include desktop {
        padding: 20px;
        border-radius: 5px;
    }

    &-Heading {
        @include mobile {
            display: none;
        }
    }

    &-AddressesWrapper {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
        }
    }

    &-Addresses {
        @include desktop {
            grid-column: span 2;
        }

        .MyAccountAddressTable {
            .KeyValueTable {
                @include mobile {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-AddNewAddress {
        margin-bottom: 0;
        margin-top: 15px;

        .Button {
            @include mobile {
                width: auto;
            }
        }
    }

    &-CustomerData {
        @include desktop {
            grid-column: span 2;
        }

        td {
            word-break: break-word;
        }
    }

    &-DefaultAddress {
        width: 100%;

        @include mobile {
            margin-bottom: 20px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .MyAccountAddressTable {
            .KeyValueTable {
                @include desktop {
                    margin-bottom: 0;
                }

                tbody {
                    tr {
                        th,
                        td {
                            padding: 20px 15px;
                        }
                    }
                }
            }
        }
    }

    &-BlockTitle {
        @include h5;

        width: 100%;
        margin-bottom: 20px;

        a {
            display: block;
            margin-top: 10px;

            @include desktop {
                margin-left: 20px;
                display: inline-block;
                margin-top: 0;
            }

            &:hover {
                @include desktop {
                    --link-hover: var(--link-color);

                    text-decoration: underline;
                }
            }
        }
    }

    &-Info {
        margin-block-end: 5px;
    }

    button {
        margin-top: 16px;

        @include desktop {
            margin-right: 10px;
        }
    }

    button + button {
        margin-top: 10px;
        border: 1px solid var(--primary-base-color);
        color: var(--primary-base-color);
        background: none;

        @include desktop {
            margin-top: 18px;
            margin-right: 0;

            &:hover {
                background-color: var(--primary-base-color) !important;
                border: 1px solid var(--primary-base-color) !important;
            }
        }
    }

    &-DiscountCard {
        .DiscountCard {
            margin-top: 40px;

            @include mobile {
                margin-bottom: 20px;
            }

            @include desktop {
                margin-top: 20px;
            }

            &-Heading {
                @include mobile {
                    display: flex;
                }
            }

            &-Description {
                margin-bottom: 0;

                @include desktop {
                    max-width: 340px;
                }
            }
        }
    }

    &-CustomerAddressMissing {
        color: var(--notification-info-color);
        background: var(--notification-info-background);
        padding: 10px 20px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: $letter-spacing1;
        align-items: center;
        display: flex;
        width: 100%;
        margin-bottom: 20px;
    }
}
