/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --my-account-background: var(--account-background-color);
    --my-account-content-background: var(--color-white);
    --my-account-wrapper-margin-mobile: 0;
    --my-account-content-border: 0;
    --my-account-order-subheading-border: 1px solid var(--color-gray3);
    --my-account-order-subheading-color: #{$default-secondary-light-color};
}

.MyAccount {
    background: var(--my-account-background);
    
    @include mobile {
        padding-bottom: 20px;
    }

    @include desktop {
        padding-bottom: 70px;
    }

    @include print-only {
        background: var(--color-white);
    }

    & &-Wrapper {
        display: grid;
        grid-template-columns: 280px auto;
        grid-column-gap: 30px;
        grid-row-gap: 0;

        @include mobile {
            grid-column-gap: 28px;
            padding: 0;
            grid-template-columns: 1fr;
        }
    }

    &-TabContent,
    .MyAccountMyOrders {
        background: var(--my-account-content-background);
        padding: 20px 10px 0 10px;

        @include mobile {
            min-height: 115px;
        }

        @include desktop {
            border: var(--my-account-content-border);
            grid-row: span 2;
            padding: 20px;
            border-radius: 5px;
        }

        &_activeTab {
            &_my-returns {
                .MyAccount {
                    &-Heading {
                        display: none;
                    }
                }
            }

            &_my-orders {
                .MyAccount {
                    &-Heading {
                        display: none;
                    }

                    &-SubHeading {
                        display: block;
                        border-radius: 16px;
                        border: var(--my-account-order-subheading-border);
                        color: var(--my-account-order-subheading-color);
                        font-size: 12px;
                        font-weight: $font-weight-medium;
                        padding: 6px 15px;
                        line-height: 13px;
                        text-transform: uppercase;

                        @include desktop {
                            margin-left: 14px;
                        }
                    }
                }
            }
        }
    }

    &-TabContent {
        &_activeTab {
            &_my-account,
            &_my-wishlist,
            &_edit,
            &_MY_ACCOUNT_STORE_CREDIT,
            &_newsletter-subscription,
            &_MY_ACCOUNT_GIFT_CARD,
            &_my-returns,
            &_MY_ACCOUNT_CASHBACK,
            &_MY_ACCOUNT_DISCOUNT_CARD {
                background: none;
                padding: 0;
    
                .MyAccount-Heading {
                    display: none;
                }
            }
        }
    }

    &-Heading {
        text-transform: none;
        margin-bottom: 30px;

        @include mobile {
            display: none;
        }
    }

    &-SubHeading {
        color: var(--secondary-dark-color);
        font-size: 16px;
    }
}
