/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/mixins';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * KemanaPWA - Progressive Web App for Magento
 * Copyright © Kemana, Inc. All rights reserved.
 *
 * @package kemana-return-orders
 * @author ashangunawardena
 */

:root {
    --return-orders-background: var(--my-account-content-background);
}

.MyAccountMyReturnOrders {
    background: var(--return-orders-background);
    padding: 20px 10px;
    width: 100%;

    @include desktop {
        padding: 20px;
        border-radius: 5px;
    }

    &-Heading {
        @include mobile {
            display: none;
        }
    }

    &-NoData {
        p {
            margin-bottom: 0;
        }
    }

    &-Table {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 0;

        & tr {
            cursor: pointer;

            td:first-child {
                color: var(--primary-base-color);
            }

            @include mobile {
                th:nth-child(3),
                td:nth-child(3) {
                    display: none;
                }
            }
        }
    }

    &-TableRow {
        td {
            &:last-child {
                text-transform: lowercase;
                display: inline-block;

                &:first-letter {
                    text-transform: uppercase
                }
            }
        }
    }
}
