/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CreateAccount {
    .ContentWrapper {
        padding-block-start: 28px;
        padding-block-end: 50px;
        padding-inline: 20px;

        @include desktop() {
            padding-block-start: 25px;
        }
    }

    &-InnerWrapper {
        @include desktop {
            max-width: 360px;
            margin: 0 auto;
            box-shadow: $box-shadow9;
            background-color: var(--color-white);
            padding: 20px 0;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        input {
            width: 100%;
        }

        p {
            margin-block-end: 20px;
        }

        .MyAccountOverlay-SignUpButton {
            width: 100%;
            margin-top: 20px;

            @include desktop {
                margin-top: 35px;
            }
        }
    }

    &-CreateAccountWrapper {
        flex: 1;
        margin: 0 20px;
    }

    .Loader {
        margin: 0;
    }
}

.CreateAccountPage {
    @include desktop {
        margin-bottom: 25px;
        margin-top: -12px;
        background-color: var(--color-gray5);
    }

    .ContentWrapper {
        @include desktop {
            padding-top: 30px;
            padding-bottom: 70px;
        }
    }
}
