/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountOverlay {
    &-Legend {
        legend {
            @include h5;

            padding: 20px 0 0 0;

            @include desktop() {
                padding: 30px 0 0 0;
            }
        }

        .Field-Wrapper_type_checkbox {
            .input-control {
                min-width: 15px;
                min-height: 15px;
            }
        }
    }

    &-Disabled {
        .FieldSelect {
            &-Select {
                background-color: var(--color-gray4);
            }
        }
    }

    &-Inputtext {
        font-size: 12px;
        line-height: 18px;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing1;
        color: var(--secondary-light-color);
        margin-top: 5px;
        display: block;
    }

    &-Section {
        > .Field {
            &-Wrapper {
                &_type_text {
                    @include first-and-last-single;

                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &-SignUpButton {
        margin-top: 24px;

        @include mobile {
            margin-bottom: 80px;
            margin-top: 35px !important;
        }
    }

    &-SignInButton {
        height: fit-content;
    }
}
