
@mixin h1 {
    color: $h1-font-color;
    font-size: $h1-font-size-desktop;
    line-height: $h1-font-line-height-desktop;
    font-weight: $h1-font-weight;
    letter-spacing: $h1-letter-spacing;

    @include mobile {
        font-size: $h1-font-size-mobile;
        line-height: $h1-font-line-height-mobile;
    }
}

@mixin h2 {
    color: $h2-font-color;
    font-size: $h2-font-size-desktop;
    line-height: $h2-font-line-height-desktop;
    font-weight: $h2-font-weight;
    letter-spacing: $h2-letter-spacing;

    @include mobile {
        font-size: $h2-font-size-mobile;
        line-height: $h2-font-line-height-mobile;
    }
}

@mixin h3 {
    color: $h3-font-color;
    font-size: $h3-font-size-desktop;
    line-height: $h3-font-line-height-desktop;
    font-weight: $h3-font-weight;
    letter-spacing: $h3-letter-spacing;

    @include mobile {
        font-size: $h3-font-size-mobile;
        line-height: $h3-font-line-height-mobile;
    }
}

@mixin h4 {
    color: $h4-font-color;
    font-size: $h4-font-size-desktop;
    line-height: $h4-font-line-height-desktop;
    font-weight: $h4-font-weight;
    letter-spacing: $h4-letter-spacing;

    @include mobile {
        font-size: $h4-font-size-mobile;
        line-height: $h4-font-line-height-mobile;
    }
}

@mixin h5 {
    color: $h5-font-color;
    font-size: $h5-font-size-desktop;
    line-height: $h5-font-line-height-desktop;
    font-weight: $h5-font-weight;
    letter-spacing: $h5-letter-spacing;

    @include mobile {
        font-size: $h5-font-size-mobile;
        line-height: $h5-font-line-height-mobile;
    }
}

@mixin h6 {
    color: $h6-font-color;
    font-size: $h6-font-size-desktop;
    line-height: $h6-font-line-height-desktop;
    font-weight: $h6-font-weight;
    letter-spacing: $h6-letter-spacing;

    @include mobile {
        font-size: $h6-font-size-mobile;
        line-height: $h6-font-line-height-mobile;
    }
}

@mixin sub-heading-1 {
    color: $subtitle-primary-font-color;
    letter-spacing: $subtitle-primary-letter-spacing;
    font-weight: $subtitle-primary-font-weight;
    font-size: $subtitle-primary-font-size;
    line-height: $subtitle-primary-font-line-height;
}

@mixin sub-heading-2 {
    color: $subtitle-secondary-font-color;
    letter-spacing: $subtitle-secondary-letter-spacing;
    font-weight: $subtitle-secondary-font-weight;
    font-size: $subtitle-secondary-font-size;
    line-height: $subtitle-secondary-font-line-height;
}

@mixin paragraph-text {
    font-family: $paragraph-font-family;
    font-weight: $paragraph-font-weight;
    color: $paragraph-font-color;
    letter-spacing: $paragraph-letter-spacing;
    font-size: $paragraph-font-size-desktop;
    line-height: $paragraph-font-line-height-desktop;
}

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

@mixin qty-selector {
    margin-top: 0;
    display: flex;
    cursor: default;

    input {
        --input-line-height: 28px;

        font-weight: $font-weight-regular;
        background: transparent;
        border: 0;
        color: var(--primary-dark-color);
        text-align: center;
        padding: 0;
        order: 2;
        width: 49px;
        min-height: 28px;
        top: 1px;
        border-bottom: 1px solid var(--secondary-divider-color);
        border-radius: 0;
    }

    button {
        position: relative;
        order: 1;
        background-color: var(--cart-item-actions-color);
        width: 20px;
        height: 20px;
        left: 0;
        color: $white;
        border-radius: 2rem;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-of-type {
            @include qty-plus-thin-icon;

            left: 0;
            order: 3;

            &::before {
                margin-left: 1px;
            }
        }

        &:last-of-type {
            @include qty-minus-thin-icon;

            right: 0;
            order: 1;

            &::before {
                margin-top: 1px;
            }
        }

        span {
            @include mobile {
                top: -2.2px;
            }
        }

        &:hover {
            @include desktop {
                background-color: var(--secondary-base-color);
            }
        }

        &:disabled {
            background-color: var(--color-gray3);
            opacity: 1;

            &::before {
                color: var(--color-grey12);
            }
        }

        &:not([disabled]) {
            cursor: pointer;
        }
    }
}

@mixin custom-scroll {
    /* width */
    
    &::-webkit-scrollbar {
        width: 20px;
    }

    /* Track */

    &::-webkit-scrollbar-track {
        background: #{$grey5};
    }

    /* Handle */

    &::-webkit-scrollbar-thumb {
        background: #{$grey7}; 
        border-radius: 25px;
        border: 6px solid #{$grey5};
    }
}

@mixin first-and-last-single {
    &:nth-child(1),
    &:nth-child(2) {
        width: 48.5%;
        float: left;
        margin-bottom: 2rem;
        margin-top: 20px;
    }

    &:nth-child(1) {
        margin-right: 3%;
    }

    &:nth-child(3) {
        clear: both;
    }
}

@mixin cart-discount {
    border-top: 1px solid var(--secondary-divider-color);

    &:first-of-type {
        @include mobile {
            border-width: 1px;
        }
    }

    &:last-of-type {
        @include mobile {
            border-bottom: 0;
        }
    }

    .ExpandableContent {
        &-Button {
            padding-top: 25px;
            padding-bottom: 25px;
            margin-bottom: 0;
            justify-content: inherit;
            cursor: pointer;

            @include discount-code-icon;
            @include arrow-down-large-icon;

            &::before {
                margin-right: 10px;
            }

            &::after {
                font-size: 9px;
                position: absolute;
                right: 0;
            }

            svg {
                display: none;
            }

            &_isContentExpanded {
                @include arrow-up-large-icon;

                padding-bottom: 0;

                &::after {
                    font-size: 9px;
                }
            }
        }

        &-Content {
            &_isContentExpanded {
                padding-bottom: 25px;
            }
        }

        &-Heading {
            @include sub-heading-2;

            font-weight: $font-weight-medium;
            line-height: 18px;
            cursor: pointer;

            @include mobile {
                padding-right: 15px;
            }

            &::after {
                @include sub-heading-1;

                content: 'Optional';
                line-height: 18px;
            }
        }
    }
}

@mixin cart-gift-card {
    border-top: 1px solid var(--secondary-divider-color);

    &:first-of-type {
        @include mobile {
            border-width: 1px;
        }
    }

    &:last-of-type {
        @include mobile {
            border-bottom: 0;
        }
    }

    .ExpandableContent {
        &-Button {
            padding-top: 25px;
            padding-bottom: 25px;
            margin-bottom: 0;
            justify-content: inherit;
            cursor: pointer;

            @include gift-card-icon;
            @include arrow-down-large-icon;

            &::before {
                margin-right: 10px;
            }

            &::after {
                font-size: 9px;
                position: absolute;
                right: 0;
            }

            svg {
                display: none;
            }

            &_isContentExpanded {
                @include arrow-up-large-icon;

                padding-bottom: 0;

                &::after {
                    font-size: 9px;
                }
            }
        }

        &-Content {
            &_isContentExpanded {
                padding-bottom: 25px;
            }
        }

        &-Heading {
            @include sub-heading-2;

            font-weight: $font-weight-medium;
            line-height: 18px;
            cursor: pointer;

            @include mobile {
                padding-right: 15px;
            }

            &::after {
                @include sub-heading-1;

                content: 'Optional';
                line-height: 18px;
            }
        }
    }
}

@mixin cash-back-points {
    border-top: 1px solid var(--secondary-divider-color);

    &:first-of-type {
        @include mobile {
            border-width: 1px;
        }
    }

    &:last-of-type {
        @include mobile {
            border-bottom: 0;
        }
    }

    .ExpandableContent {
        &-Button {
            padding-top: 25px;
            padding-bottom: 25px;
            margin-bottom: 0;
            justify-content: inherit;
            cursor: pointer;

            @include cashback-point-icon;
            @include arrow-down-large-icon;

            &::before {
                margin-right: 10px;
            }

            &::after {
                font-size: 9px;
                position: absolute;
                right: 0;
            }

            svg {
                display: none;
            }

            &_isContentExpanded {
                @include arrow-up-large-icon;

                padding-bottom: 0;

                &::after {
                    font-size: 9px;
                }
            }
        }

        &-Content {
            &_isContentExpanded {
                padding-bottom: 25px;
            }

            .RedeemCashBack {
                margin-top: 16px;
                font-size: 14px;
                letter-spacing: $letter-spacing1;

                .RedeemCash,
                .StoreCredit {
                    &-Button {
                        --button-padding: 0 24px;

                        margin-top: 10px;
                        width: auto;
                        display: flex;

                        span {
                            color: var(--hollow-button-color);
                        }

                        &:hover {
                            span {
                                @include desktop {
                                    color: var(--hollow-button-hover-color);
                                }
                            }
                        }
                    }

                    &-RemoveButton {
                        position: absolute;
                        right: 0;
                        top: 2px;
                        cursor: pointer;

                        @include close-icon;

                        &::before {
                            font-size: 15px;
                            line-height: 15px;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }
        }

        &-Heading {
            @include sub-heading-2;

            font-weight: $font-weight-medium;
            line-height: 18px;
            cursor: pointer;

            @include mobile {
                padding-right: 15px;
            }

            &::after {
                @include sub-heading-1;

                content: 'Optional';
                line-height: 18px;
            }
        }
    }
}

@mixin store-credit {
    border-top: 1px solid var(--secondary-divider-color);

    &:first-of-type {
        @include mobile {
            border-width: 1px;
        }
    }

    &:last-of-type {
        @include mobile {
            border-bottom: 0;
        }
    }

    .ExpandableContent {
        &-Button {
            padding-top: 25px;
            padding-bottom: 25px;
            margin-bottom: 0;
            justify-content: inherit;
            cursor: pointer;

            @include arrow-down-large-icon;

            &::before {
                content: '';
                background: url(../../../public/images/store-credit.svg);
                background-repeat: no-repeat;
                background-size: contain;
                width: 18px;
                height: 18px;
                margin-right: 10px;
            }

            &::after {
                font-size: 9px;
                position: absolute;
                right: 0;
            }

            svg {
                display: none;
            }

            &_isContentExpanded {
                @include arrow-up-large-icon;

                padding-bottom: 0;

                &::after {
                    font-size: 9px;
                }
            }
        }

        &-Content {
            &_isContentExpanded {
                .StoreCreditWrapper {
                    padding-bottom: 25px;

                    .StoreCredit {
                        .StoreCredit {
                            @include mobile {
                                padding-right: 15px;
                            }
                        }
                    }
                }
            }

            .StoreCredit {
                margin-top: 16px;
                font-size: 14px;
                letter-spacing: $letter-spacing1;

                .RedeemCash,
                .StoreCredit {
                    width: 100%;
                    display: flex;

                    &-Button {
                        --button-padding: 0 24px;
                        
                        margin-top: 10px;
                        width: auto;
                        display: flex;

                        span {
                            color: var(--hollow-button-color);
                        }

                        &:hover {
                            span {
                                @include desktop {
                                    color: var(--hollow-button-hover-color);
                                }
                            }
                        }
                    }

                    &-RemoveButton {
                        position: absolute;
                        right: 0;
                        top: 2px;
                        cursor: pointer;

                        @include close-icon;

                        &::before {
                            font-size: 15px;
                            line-height: 15px;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }
        }

        &-Heading {
            @include sub-heading-2;

            font-weight: $font-weight-medium;
            line-height: 18px;
            cursor: pointer;

            @include mobile {
                padding-right: 15px;
            }

            &::after {
                @include sub-heading-1;

                content: 'Optional';
                line-height: 18px;
            }
        }
    }
}

@mixin legend-form-border {
    border-bottom: 1px solid var(--primary-divider-color);
    padding-bottom: 20px;
}
