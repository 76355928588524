/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --my-account-tab-list-background: #{$grey17};

    @include desktop {
        --my-account-tab-list-separator-color: rgba(10, 9, 3, 0.16);
    }
}

.ExpandableContent {
    &.MyAccountTabList {
        background-color: var(--my-account-tab-list-background);
    
        @include mobile {
            padding: 20px 10px;
        }
    
        @include desktop {
            background: $white;
            padding: 0 20px;
        }

        .MyAccountTabList {
            &Item {
                @include mobile {
                    padding-top: 1px;
                    padding-bottom: 0;
                    padding-left: 0;
                    padding-right: 0;
                    border-bottom: 1px solid var(--primary-divider-color);
                }

                &:last-of-type {
                    @include mobile {
                        border-color: transparent;
                    }
                }

                &::after {
                    @include mobile {
                        display: none;
                    }
                }

                &-Button {
                    @include mobile {
                        @include myaccount-next-nav;

                        padding-left: 15px;
                        padding-right: 15px;
                        min-height: 40px;
                    }
                }
            }

            &-ExpandableContentContent {
                @include desktop {
                    margin-block-start: 0;
                }
                
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                }
        
                &_isContentExpanded {
                    @include mobile {
                        padding-top: 0;
                        padding-left: 0;
                        padding-right: 0;
                        background: var(--body-background-color);
                        border: 1px solid var(--primary-divider-color);
                        border-top: 0;
                    }
                }
            }
        
            &-ExpandableContentButton {
                padding: 9px 15px 10px;
                border: 1px solid var(--primary-divider-color);
                width: auto;
                background: var(--body-background-color);
        
                @include desktop {
                    display: none !important;
                }
        
                &::before,
                &::after {
                    inset-inline-end: 24px;
        
                    @include mobile {
                        inset-inline-end: 28px;
                    }
                }
            }
        
            &-ExpandableContentHeading {
                color: var(--secondary-dark-color);
                font-size: 14px;
                line-height: 19px;
                font-weight: $font-weight-regular;
                letter-spacing: $letter-spacing1;
            }
        
            &-Separator {
                display: none;
            }
        }
    }
}
